.form-errors {
    $padding: .5rem;

    position: fixed;
    right: 0.5rem;
    bottom: 0;

    max-width: 320px;
    width: 100%;
    border-radius: 0.5rem;

    max-height: 50vh;

    width: 100%;

    display: flex;

    flex-direction: column;
    border-radius: 15px 15px 0 0;
    background-color: white;
    z-index: 10;

    .card-header {
        background-color: $primary;
        color: $primary-text;
        font-weight: bold;
        position: relative;

        >.expandable {
            position: absolute;
            right: 26px;
            height: 1.5em;
            width: 1.5em;
            background-color: transparent;
            border-width: 0;
            color: $white;

            >* {
                font-size: 2.5rem;
                line-height: 1.5rem;
            }
        }
    }

    .card-header,
    .card-body {
        padding-top: $padding;
        padding-bottom: $padding;
    }

    .form-error-list {
        overflow-y: auto;
        background-color: $white;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        .error-item {
            &:not(:first-child)::before {
                width: 75%;
                height: 1px;
                background-color: $primary;
                content: " ";
                align-self: center;
            }

            &:first-child{
                border-radius: 4px 4px 0 0 ;
            }
            &:last-child{
                border-radius: 0 0 4px 4px;
            }

            display: flex;
            flex-direction: column;
            margin-bottom: 1px;
            color: $primary;
            text-align: left;

            > * {
                overflow-wrap: anywhere;
                color: $primary;
            }
        }
    }
}
