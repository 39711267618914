.case-note-add {
    textarea{
        width: 100%;
        height: 100px;
    }

    margin-bottom: 1rem;

}

.case-notes-list .case-note-container{
    margin-bottom: 1rem;

    .case-note-header{
        font-size: small;
    }
    
    .case-note-date-time{
        font-size: small;
        font-weight: bold;
        &::after {
            content: " - ";
        }
    }

    .case-note-action{
        float: right;
        font-size: small;
        font-weight: bold;
    }

    .case-note-text {
        white-space: pre-wrap;
    }
}

