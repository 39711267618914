@import "mixin";


@include fieldobject(smallThreeRows) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .file-input > .input-group-text {
        width: 10em;
    }


    .field-description{
        grid-column: 1/4;
    }

}


@include fieldobject(smallFourRows) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .file-input > .input-group-text {
        width: 10em;
    }


    .field-description{
        grid-column: 1/5;
    }

}


@include fieldobject(smallFiveRows) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .file-input > .input-group-text {
        width: 10em;
    }


    .field-description{
        grid-column: 1/6;
    }

}