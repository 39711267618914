$formtab-color: $primary;
$formtab-hover-color: darken($formtab-color, 20%);
$formtab-hover-bg: transparentize($formtab-color, .95);


.case-form-nav-panel {
    position: fixed;
    top: 6rem;
    left: 0;
    width: 100%;
    color: $formtab-color;
    overflow: hidden;
    max-height: calc(100vh - 200px);
    height: 100%;

    .scroller {
        overflow: hidden auto;
        max-height: calc(100vh - 200px);
    }

    &.expanded {
        max-width: 320px;
        .scroller {
            padding: 1rem 1rem;
            max-width: 320px;
        }
        border-radius: 0.5rem;
        text-align: center;
    }

    h5 {
        margin-bottom: .5rem;
        cursor: default;
    }

    ul.nav {
        border-top: 1px $formtab-color solid;
        border-bottom: 1px $formtab-color solid;
    }

    a {
        color: inherit;
        cursor: pointer;
    }

    .nav-item{
        border-radius: 0.5rem;

        :hover {
            color: $formtab-hover-color;
            background: $formtab-hover-bg;
        }

        .active {
            background: $formtab-color;
            color: white;
            box-shadow: rgba(153, 101, 101, 0.35) 0px 5px 15px;
        }
    }

    .separator {
        height: 1px;
        background-color: $formtab-color;
        margin: 0 10%;
    }
  
    .section-title {
        color: $formtab-color;
    }
}


@include media-breakpoint-up(xxl) {
    .case-form-nav-panel {
        .expand-button {
            display: none;
        }
        .nav-item .icon {
            display: none;
        }
        max-width: 320px;
        padding: 1rem 1rem;
        border-radius: 0.5rem;
        text-align: center;
    }
}



@include media-breakpoint-down(xl) {
    .case-form-nav-panel {
        $max-width: 2.2em;
        $btn-width: 2.2em * .8;

        .expand-button {
            position: absolute;
            left: 0rem;
            display: block;
            border-radius: 0.5rem;
            height: 2em;
            width: 2em;
            padding-left: 0;
            padding-right: 0;
            text-align: center;
        }

        &.expanded {
            background-color: white;
            border: 1px solid gray;
            z-index: 99999;
            box-shadow: rgba(153, 101, 101, 0.35) 0px 5px 15px;
            .expand-button {
                float: left;
            }
            .nav-item .icon {
                display: none;
            }    
        }

        &:not(.expanded) {
            max-width: $max-width * 1.3;
            .scroller {
                padding: 2rem 0rem;
            }
            border-radius: 0.5rem;
            text-align: left;
            h5 { display: none; }
            .nav {
                border-color: transparent;
                .nav-item {
                    display: block;
                    color: transparent;
                    border: 1px solid $formtab-color;
                    height: $btn-width;
                    width: $btn-width;
                    position: relative;

                    .icon {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        color: $formtab-color;
                        transform: translate(-50%, -50%);
                    }

                    &:hover {
                        background: $formtab-hover-bg;
                    }
            
                    &.active {
                        background: $formtab-color;
                        .icon {
                            color: $white;
                        }
                    }

                    a {
                        display: block;
                        height: $btn-width;
                        width: $btn-width;
                        opacity: 0;
                        overflow: hidden;
                    }
                }
                .separator {
                    display: none;
                }
            }
        }
    }
}