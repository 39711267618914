.log-entry {
    margin-top: 2px;
}

.log-entry:last-child {
    margin-top: 32px;
}

.log-entry .card-header{
    position: relative;
}
.log-entry .card-header > .action{
    position: absolute;
    right: 15px;
    font-size: .7em;
}
.log-entry .card-header > .index{
    display: inline-block;
    min-width: 2em;
    text-align: center;
    padding-right: 1em;
}
.log-entry .card-header > .status{
    display: inline-block;
}

.log-entry .card-header > .status{
    display: inline-block;
}


.log-entry .state {
    font-family: monospace;
    font-size: .8em;
    background-color: rgba(128, 128, 128, .1);
    white-space: pre;
}

.log-entry .timestamp {
    font-size: .9em;
}