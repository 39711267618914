@import "mixin";


@include fieldobject(smallTwoRows) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .file-input > .input-group-text {
        width: 10em;
    }

    .field-description{
        grid-column: 1/3;
    }
    .aditionalFiles  {grid-column-start: 1; grid-column-end: 3}
    .soporteGarantiaFile  {grid-column: 1; grid-row: 2;}
    .soporteGarantiaFecha {grid-column: 2; grid-row: 2;}
}


.smallTwoRows.default-all-row > * > * {
    grid-column-start: 1;
    grid-column-end: 4;
}
