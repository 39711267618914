.case-form.hidden {
    display: none;
}

form.rjsf {
    margin-top: -1.25rem;

    .form-group {
        .control-label {
            margin-bottom: .5rem;
        }

        .checkbox-inline>span {
            margin-right: $inline-input-gap;

            >input[type=checkbox] {
                margin-right: $inline-label-gap;
            }
        }

        .checkbox>label>span {
            margin-right: $inline-input-gap;

            >input[type=checkbox] {
                margin-right: $inline-label-gap;
            }
        }

        .field-radio-group>.radio {
            margin-right: $inline-input-gap;

            >label>span>input[type=radio] {
                margin-right: $inline-label-gap;
            }
        }

        .radio-inline {
            margin-right: $inline-input-gap;

            >span {
                >input[type=radio] {
                    margin-right: $inline-label-gap;
                }
            }
        }

        input,
        select {
            max-width: 20rem;
        }

        .btn.btn-block {
            margin-left: 3rem;
            margin-right: 3rem;
            width: 90%;
        }
    }

    .title-2-lines>label {
        height: 3em;
    }

    .array-field-vertical .array-item-list {
        flex-direction: column;
        max-width: 30rem;

        >.array-item {
            display: flex;

            >.col-xs-9 {
                flex-grow: 1;

                .form-group {
                    margin-bottom: 0;
                }
            }

            >.col-xs-3 {
                display: flex;
                align-items: center;
            }
        }
    }

    legend[id^="root_"][id$="__title"] {
        background-color: $primary;
        color: $primary-text;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        margin-left: -1.25rem;
        margin-right: -1.25rem;
        box-sizing: content-box;
    }

    .hideObjectField_title {
        legend {
            background-color: $primary;
            color: $primary-text;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            margin-left: -1.25rem;
            margin-right: -1.25rem;
            box-sizing: content-box;
        }
    }

    .btn.btn-add {
        $color: map-get($theme-colors, "secondary");
        @include button-variant($color, $color);
    }

    .radio-column {
        >.field-radio-group {
            flex-direction: column;
        }
    }

    legend#root__title:empty {
        margin: 0;
    }
}