@import "mixin";


@include fieldobject(personalComplianceUploads-rdd) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .file-field {
        text-align: center;
        > a {
            width: 100%;
            max-width: 280px;
        }
        margin: 0;
    }

    .banco  {grid-column: 1;}
    .puesto {grid-column: 2;}
    .safi   {grid-column: 3;}
    .reporte { grid-row: 1; }
    .reporteSimple { grid-row: 2; }
}

@include fieldobject(personalComplianceUploads-upload) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .file-field {
        > .file-input {
            flex-wrap: nowrap;
            > .input-group-text {
                width: auto; flex-shrink: 100; max-width: 15em;
            }
            .btn {
                white-space: nowrap;
            }
        }
    }
}

@include fieldobject(businessComplianceUploads-bdd) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .file-field {
        text-align: center;
        > a {
            width: 100%;
            max-width: 280px;
        }
        margin: 0;
    }
    .title { grid-row: 1; align-self: flex-end; justify-self: center; }
    .col1  {grid-column: 1;}
    .col2  {grid-column: 2;}
    .col3  {grid-column: 3;}
    .row2  { grid-row: 2; }
    .row3  { grid-row: 3; }
    .row4  { grid-row: 4; }
}

@include fieldobject(businessComplianceUploads-upload) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .file-field {
        > .file-input {
            flex-wrap: nowrap;
            > .input-group-text {
                width: auto; flex-shrink: 100; max-width: 15em;
            }
            .btn {
                white-space: nowrap;
            }
        }
    }
    .title { grid-row: 1; align-self: flex-end; justify-self: center; }
    .col1   {grid-column: 1;}
    .col2   {grid-column: 2;}
    .col3   {grid-column: 3;}
    .row2   { grid-row: 2; }
    .row3   { grid-row: 3; }
}