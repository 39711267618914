// $primary: red;
$primary: #EC1E2A;

// $secondary: ;
// $success: ;
// $info: ;
// $warning: ;
$danger: #ff4136;
// $light: ;
// $dark: ;

$accent2: #E01A76;


$primary-text: white;

// $theme-colors: ("primary":#EC1E2A, "danger": #ff4136);


// form style
$inline-input-gap: 1.5rem;
$inline-label-gap: .5rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1780px
);


// form tabs