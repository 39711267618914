

@for $i from 1 through 6 {
    .from-col-#{$i} {
        grid-column-start: $i !important;
    }

    .to-col-#{$i} {
        grid-column-end: $i !important;
    }

    .from-row-#{$i} {
        grid-row-start: $i !important;
    }

    .to-row-#{$i} {
        grid-row-end: $i !important;
    }
}


.pad-right {
    padding-right: 1rem;
}